<template>
  <div class="uello-app-faq h-100 mt-5">
    <!--div class="d-flex justify-content-between align-items-center px-3">
      <h2 class="text-dark-darken pt-4 mb-4 font-weight-bold">
        {{ $t('pageTitles.faq') }}
      </h2>
    </div-->
    <div class="h-100">
      <embed type="text/html" :src="faqLink" width="100%" height="100%" />
    </div>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { movidesk } from '@/mixins';

export default {
  name: 'uello-app-faq',
  mixins: [movidesk],
  components: {
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
  },
  data() {
    return {
      faqLink: process.env.VUE_APP_MOVIDESK_HELP_FAQ,
      movideskHasButton: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //
  },
};
</script>

<style></style>
